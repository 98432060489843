@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?iyfr79");
  src: url("fonts/icomoon.eot?iyfr79#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?iyfr79") format("truetype"), url("fonts/icomoon.woff?iyfr79") format("woff"),
    url("fonts/icomoon.svg?iyfr79#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-new-calendar:before {
  content: "\e915";
}
.icon-new-check:before {
  content: "\e916";
}
.icon-new-tickt:before {
  content: "\e917";
}
.icon-new-piggy:before {
  content: "\e918";
}
.icon-new-hand:before {
  content: "\e919";
}
.icon-new-share:before {
  content: "\e91a";
}
.icon-new-pin:before {
  content: "\e91b";
}


.icon-logo-shape:before {
  content: "\e913";
  color: #fc3f27;
}
.icon-logo-type:before {
  content: "\e914";
}


.icon-sun:before {
  content: "\e912";
}

.icon-calendar:before {
  content: "\e900";
}

.icon-camera:before {
  content: "\e901";
}

.icon-check-circle:before {
  content: "\e902";
}

.icon-complete-icon:before {
  content: "\e903";
}

.icon-deadline:before {
  content: "\e904";
}

.icon-donated:before {
  content: "\e905";
}

.icon-faq-arrow:before {
  content: "\e906";
}

.icon-logo:before {
  content: "\e907";
}

.icon-moon:before {
  content: "\e908";
}

.icon-navbarToggle:before {
  content: "\e909";
}

.icon-next-icon_1:before {
  content: "\e90a";
}

.icon-next-page:before {
  content: "\e90b";
}

.icon-piggy:before {
  content: "\e90c";
}

.icon-prev-icon:before {
  content: "\e90d";
}

.icon-prev-page-01:before {
  content: "\e90e";
}

.icon-share:before {
  content: "\e90f";
}

.icon-tag:before {
  content: "\e910";
}

.icon-ticket:before {
  content: "\e911";
}

body > .modal-backdrop.fade.show {
  background-color: transparent !important;
  backdrop-filter: blur(0) !important;
}

body > .modal-backdrop.fade.show:last-of-type {
  background-color: rgba(255, 255, 255, 0.6) !important;
  backdrop-filter: blur(4px) !important;
}
